<template>
  <div class="container text-center mt-5">
    <div class="row">
      <div class="col-sm-7">
        <h1 class="fs-1 fw-bold text-primary">DATE CALC</h1>
        <div class="w-100 row">
          <div class="col-sm-6 mb-3">
            <label for="dateform" class="form-label"
              >Date <span class="text-danger">*</span></label
            >
            <input
              v-model="date"
              type="datetime-local"
              class="form-control"
              id="dateform"
              placeholder="2024-02-15 10:50:00"
            />
          </div>
          <div class="col-sm-3 mb-3">
            <label for="hoursform" class="form-label">Hours</label>
            <input
              v-model="hours"
              type="number"
              min="0"
              class="form-control"
              id="hoursform"
              placeholder="Optional"
            />
          </div>
          <div class="col-sm-3 mb-3">
            <label for="mintform" class="form-label"
              >Minutes <span class="text-danger">*</span></label
            >
            <input
              v-model="mint"
              type="number"
              min="0"
              class="form-control"
              id="mintform"
              placeholder=""
            />
          </div>
        </div>
        <div class="d-grid gap-2">
          <button @click="calcDateMint" class="btn btn-primary" type="button">
            CALC
          </button>
        </div>
        <div v-if="dateslist.length" class="w-100 mt-5">
          <h3 class="text-start">
            Records
            <button
              @click="trashDates"
              class="btn btn-danger btn-sm float-end"
              type="button"
            >
              Delete all
            </button>
          </h3>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">INIT</th>
                  <th scope="col">HOURS</th>
                  <th scope="col">MINUTES</th>
                  <th scope="col">END</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dateslist" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ item.init }}</td>
                  <td>{{ item.hours }}</td>
                  <td>{{ item.minutes }}</td>
                  <td>{{ item.end }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <h1 class="fs-1 fw-bold text-info">MILLES CALC</h1>
        <div class="w-100 row">
          <div class="col-sm-8 mb-3">
            <label for="milles_initform" class="form-label"
              >Init <span class="text-danger">*</span></label
            >
            <input
              v-model="milles_init"
              type="number"
              min="0"
              class="form-control"
              id="milles_initform"
            />
          </div>
          <div class="col-sm-4 mb-3">
            <label for="milles_sumform" class="form-label"
              >Sum <span class="text-danger">*</span></label
            >
            <input
              v-model="milles_sum"
              type="number"
              min="0"
              class="form-control"
              id="milles_sumform"
            />
          </div>
        </div>
        <div class="d-grid gap-2">
          <button @click="calcMilles" class="btn btn-info" type="button">
            CALC
          </button>
        </div>
        <div v-if="milles.length" class="w-100 mt-5">
          <h3 class="text-start">
            Records
            <button
              @click="trashMilles"
              class="btn btn-danger btn-sm float-end"
              type="button"
            >
              Delete all
            </button>
          </h3>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">INIT</th>
                  <th scope="col">SUM</th>
                  <th scope="col">END</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in milles" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ item.init }}</td>
                  <td>{{ item.sum }}</td>
                  <td>{{ item.end }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { onMounted, ref } from "vue";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  setup() {
    //CALC DATE
    const dateslist = ref([]);
    const date = ref("");
    const hours = ref("");
    const mint = ref("");
    const resp_mint = ref("");
    //MILLES
    const milles = ref([]);
    const milles_init = ref("");
    const milles_sum = ref("");
    const milles_end = ref("");

    onMounted(() => {
      const xmilles = localStorage.getItem("milles_fsc");
      milles.value = xmilles ? JSON.parse(xmilles) : [];
      const xdates = localStorage.getItem("dates_fsc");
      dateslist.value = xdates ? JSON.parse(xdates) : [];
    });

    //FUNCTIONS
    const formatDate = (v) => {
      const d = new Date(v);
      return (
        [
          d.getDate().padLeft(),
          (d.getMonth() + 1).padLeft(),
          d.getFullYear().padLeft(),
        ].join("/") +
        " " +
        [
          d.getHours().padLeft(),
          d.getUTCMinutes().padLeft(),
          d.getUTCSeconds().padLeft(),
        ].join(":")
      );
    };
    Number.prototype.padLeft = function (base, chr) {
      var len = String(base || 10).length - String(this).length + 1;
      return len > 0 ? new Array(len).join(chr || "0") + this : this;
    };
    const calcDateMint = () => {
      if (mint.value && date.value) {
        //mint.value
        let sum = mint.value;
        if (hours.value) {
          sum = mint.value + hours.value * 60;
        }
        const xdate = new Date(date.value);
        //const xdate = new Date();
        const newDateObj = moment(xdate).add(sum, "minutes").toDate();
        resp_mint.value = formatDate(newDateObj);
        addDatesObject(
          formatDate(date.value),
          hours.value,
          mint.value,
          resp_mint.value
        );
      } else {
        alert("Complete los campos");
      }
    };
    const formatNumber = (value) => {
      const aux = new Intl.NumberFormat("en-us").format(value);
      return aux;
    };
    const calcMilles = () => {
      if (milles_init.value && milles_sum.value) {
        milles_end.value = formatNumber(milles_init.value + milles_sum.value);
        addMillesObject(
          formatNumber(milles_init.value),
          formatNumber(milles_sum.value),
          milles_end.value
        );
      } else {
        alert("Complete los campos");
      }
    };
    const addMillesObject = (a, b, c) => {
      milles.value.push({ init: a, sum: b, end: c });
      localStorage.setItem("milles_fsc", JSON.stringify(milles.value));
    };
    const trashMilles = () => {
      milles.value = [];
      localStorage.setItem("milles_fsc", JSON.stringify(milles.value));
    };
    const addDatesObject = (a, b, c, d) => {
      dateslist.value.push({ init: a, hours: b, minutes: c, end: d });
      localStorage.setItem("dates_fsc", JSON.stringify(dateslist.value));
    };
    const trashDates = () => {
      dateslist.value = [];
      localStorage.setItem("dates_fsc", JSON.stringify(dateslist.value));
    };
    return {
      calcDateMint,
      dateslist,
      date,
      hours,
      mint,
      resp_mint,
      trashDates,
      milles,
      milles_init,
      milles_sum,
      milles_end,
      calcMilles,
      trashMilles,
    };
  },
};
</script>
